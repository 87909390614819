import React from "react";
import ProfilePageHeader from "components/Headers/ProfilePageHeader";

import Navbar from "components/Navbar";
import Footer from "components/Footer";

import News1 from "components/news/news1";
import News2 from "components/news/news2";
import News3 from "components/news/news3";
import News5 from "components/news/news5";
import News6 from "components/news/news6";
import News7 from "components/news/news7";
import News8 from "components/news/news8";
import News9 from "components/news/news9";
import News10 from "components/news/news10";
import News11 from "components/news/news11";
import News12 from "components/news/news12";
import News13 from "components/news/news13";
import News14 from "components/news/news14";
import News15 from "components/news/news15";
import News16 from "components/news/news16";
import News17 from "components/news/news17";
import News18 from "components/news/news18";
import News19 from "components/news/news19";
import News20 from "components/news/news20";
import News21 from "components/news/news21";
import News22 from "components/news/news22";
import News23 from "components/news/news23";
import News24 from "components/news/news24";
import News25 from "components/news/news25";
import News26 from "components/news/news26";
import News27 from "components/news/news27";
import News28 from "components/news/news28";
import News29 from "components/news/news29";
import News30 from "components/news/news30";
import News31 from "components/news/news31";
import News32 from "components/news/news32";

import { live } from "./content";

import ScrollUpButton from "react-scroll-up-button";

function News({ match }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  });
  const News = match.params.id;
  const n = live.find((x) => x.link === News);
  return (
    <div id="outer-container">
      <Navbar />
      <ScrollUpButton />
      <section id="" />
      <ProfilePageHeader title={n.description} />
      {n.id === 1 && <News1 news={n} />}
      {n.id === 2 && <News2 news={n} />}
      {n.id === 3 && <News3 news={n} />}
      {n.id === 5 && <News5 news={n} />}
      {n.id === 6 && <News6 news={n} />}
      {n.id === 7 && <News7 news={n} />}
      {n.id === 8 && <News8 news={n} />}
      {n.id === 9 && <News9 news={n} />}
      {n.id === 10 && <News10 news={n} />}
      {n.id === 11 && <News11 news={n} />}
      {n.id === 12 && <News12 news={n} />}
      {n.id === 13 && <News13 news={n} />}
      {n.id === 14 && <News14 news={n} />}
      {n.id === 15 && <News15 news={n} />}
      {n.id === 16 && <News16 news={n} />}
      {n.id === 17 && <News17 news={n} />}
      {n.id === 18 && <News18 news={n} />}
      {n.id === 19 && <News19 news={n} />}
      {n.id === 20 && <News20 news={n} />}
      {n.id === 21 && <News21 news={n} />}
      {n.id === 22 && <News22 news={n} />}
      {n.id === 23 && <News23 news={n} />}
      {n.id === 24 && <News24 news={n} />}
      {n.id === 25 && <News25 news={n} />}
      {n.id === 26 && <News26 news={n} />}
      {n.id === 27 && <News27 news={n} />}
      {n.id === 28 && <News28 news={n} />}
      {n.id === 29 && <News29 news={n} />}
      {n.id === 30 && <News30 news={n} />}
      {n.id === 31 && <News31 news={n} />}
      {n.id === 32 && <News32 news={n} />}
      
      <Footer />
    </div>
  );
}

export default News;
