import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col lg={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/main${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="align-self-center mt-3">
            <p>
              Sameh Anas joined GIG Egypt in June 2024 and is currently serving as the Managing
              Director of GIG Life Egypt, He brings a wealth of strategic insight and operational
              expertise to the role.
              <br />
              <br />
              Sameh has achieved both the Associate Customer Service (ACS) and Professional Customer
              Service (PCS) designations from LOMA. Additionally, he is a proud holder of a Master
              Black Belt in Lean Six Sigma.
              <br />
              <br />
              His expertise spans critical disciplines, including sales and business management,
              financial planning, customer relationship management (CRM), new product development,
              marketing strategy, and operations. My leadership style emphasizes integrity, agility,
              and a focus on delivering long-term value to stakeholders.
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i style={{ fontSize: "50px" }} className="fa fa-facebook-square float-left" />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i style={{ fontSize: "50px" }} className="fa fa-linkedin-square float-left" />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i style={{ fontSize: "50px" }} className="fa fa-twitter-square float-left" />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <br />
            <br />
            <p>
              <strong>
                Q-The insurance industry is highly competitive, with evolving customer expectations
                and regulatory changes.What key strategies are you implementing at GIG Life Egypt to
                strengthen its market position?
              </strong>
            </p>

            <p>
              At GIG Life Egypt, we are focusing on three key strategies to solidify our market
              position:
              <br />
              <b>Customer-Centric Innovation:</b> We are developing tailored products that address
              evolving customer needs, ensuring seamless digital interactions and enhancing service
              efficiency.
              <br />
              <b>Operational Excellence:</b> By streamlining processes and leveraging data
              analytics, we aim to improve underwriting accuracy, risk assessment, and claims
              management.
              <br />
              <b>Regulatory Adaptability:</b> We stay ahead of regulatory changes through proactive
              compliance measures, close collaboration with regulatory bodies, and an agile approach
              to policy adaptation.
              <br />
              <b>Sustainable & Profitable Growth</b>, continue our balanced growth between organic
              and non-organic growth and with healthy and profitable direction.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Leadership transitions often bring fresh perspectives and new directions.How did
                your leadership experience at AXA Egypt influence your approach to managing GIG Life
                Egypt?
              </strong>
            </p>
            <p>
              My experience at <b>AXA Egypt</b> instilled a deep appreciation for{" "}
              <b>agility, customer focus, and digital transformation</b>. I witnessed firsthand the
              power of leveraging technology to enhance customer experience and drive operational
              efficiency. This experience shapes my leadership at GIG Life Egypt, where I emphasize:
              <br />
              <br />
              <b>
                <ul>
                  <li>A data-driven decision-making approach</li>
                  <li>Encouraging innovation while maintaining financial discipline</li>
                  <li>Building a culture of empowerment and collaboration</li>
                </ul>
              </b>
            </p>
            <br />
            <p>
              <strong>
                Q-Digital transformation is reshaping customer experiences in the insurance
                sector.Can you share the journey of developing the eService platform and its impact
                on customer engagement?
              </strong>
            </p>
            <p>
              The eService platform was developed to address key customer pain points, such as
              policy management, claims tracking, and premium payments. The journey included:
              <ul>
                <li>
                  <b>Identifying pain points</b> through customer feedback
                </li>
                <li>
                  <b>Implementing a user-friendly digital interface </b>with real-time support
                </li>
                <li>
                  <b>Ensuring security & regulatory compliance</b>Its impact has been
                  significant—higher customer engagement, reduced turnaround time for service
                  requests, and enhanced overall satisfaction.
                </li>
              </ul>
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Striking the right balance between innovation and operational efficiency is
                crucial for sustainable growth.How do you balance fostering innovation with
                maintaining operational efficiency in the insurance industry?
              </strong>
            </p>
            <p>
              Innovation and efficiency must go hand in hand. We ensure this balance by:
              <ul>
                <li>
                  <b>Testing innovations through pilot projects</b> before full-scale implementation
                </li>
                <li>
                  <b>Leveraging automation</b> to enhance efficiency without compromising creativity
                </li>
                <li>
                  <b>Embedding a continuous improvement mindset</b> so teams stay agile while
                  maintaining high-quality operations
                </li>
              </ul>
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Process improvement methodologies like Lean Six Sigma play a critical role in
                driving operational excellence.What role does Lean Six Sigma play in your leadership
                style and in driving operational excellence at GIG Life Egypt?
              </strong>
            </p>
            <p>
              Lean Six Sigma plays a crucial role in our strategy by:
              <br />
              <ul>
                <li>
                  <b>Eliminating inefficiencies in underwriting, claims, and policy servicing</b>
                </li>
                <li>
                  <b>Using data analytics to improve decision-making</b>
                </li>
                <li>
                  <b>Enhancing process consistency and customer satisfaction</b>
                </li>
              </ul>
              By integrating Lean Six Sigma principles, we foster a culture of continuous
              improvement and operational discipline.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Managing diverse, multinational teams requires cultural understanding and
                effective communication. How do you foster collaboration and high performance within
                multinational teams across diverse markets?
              </strong>
            </p>
            <p>
              Leading diverse teams requires cultural intelligence and inclusive leadership. We
              promote:
              <ul>
                <li>
                  <b>Clear communication & collaboration tools</b>
                </li>
                <li>
                  <b>Encouraging cross-cultural learning</b> to respect diverse perspectives
                </li>
                <li>
                  <b>Aligning everyone with shared goals</b> to drive synergy across markets
                </li>
              </ul>
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Strategic priorities will shape the company’s direction and market impact.What are
                the top priorities for GIG Life Egypt under your leadership in the coming years?
              </strong>
            </p>
            <p>
              Our top priorities at GIG Life Egypt include:
              <ul>
                <li>
                  <b>Digital Transformation Acceleration</b>: Enhancing self-service options and
                  AI-driven customer insights
                </li>
                <li>
                  <b>Product Innovation</b>: Developing tailored life insurance solutions for
                  emerging customer needs
                </li>
                <li>
                  <b>Operational Excellence</b>: Streamlining processes for enhanced efficiency and
                  risk management
                </li>
                <li>
                  <b>Open new markets and strengthen our distribution partnership.</b>
                </li>
              </ul>
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q-Placing the customer at the heart of the business is essential for long-term
                success.How are you fostering a customer-centric culture at GIG Life Egypt?
              </strong>
            </p>
            <p>
              A customer-first mindset is at the core of GIG Life Egypt. We achieve this through:
              <ul>
                <li>
                  <b> Proactive customer feedback mechanisms</b> to shape product and service
                  improvements
                </li>
                <li>
                  <b>Empowering frontline teams</b> with training and decision-making authority
                </li>
                <li>
                  <b>Technology-driven personalization</b> to offer tailored experiences
                </li>
              </ul>
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
